import React, { useState, useEffect } from 'react';
import { Table, Switch, message } from 'antd';

const DiscountTable = ({ facilityId, refresh }) => {
  // For demo purposes, the token is hard-coded.
  // In production, obtain this from a secure source (e.g. Redux or environment variables).
  const token = '6980535cf67774d5a0854ae195b338dd';
  const apiUrl = process.env.REACT_APP_API_URL;

  // State for discount data and loading indicator.
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // Mapping of our three API columns to their discount type (or api_key_id) values.
  const discountTypeMapping = {
    webCms: 10,
    csrScape: 9,
    msn: 868,
  };

  // Fetch the current filters (i.e. discount filters) from the API.
  const fetchFilters = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/v1/facilities/${facilityId}/api_filters`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!response.ok) {
        throw new Error('Failed to fetch filters');
      }
      const filters = await response.json();
      return filters;
    } catch (error) {
      console.error('Error fetching filters:', error);
      message.error('Failed to load filters data.');
      return [];
    }
  };

  // Fetch discounts, then merge with filters so we can store the matching filter record ID.
  const fetchDiscounts = async () => {
    if (!facilityId || !token) {
      console.warn('Missing facilityId or token');
      return;
    }
    setLoading(true);
    try {
      console.log(
        'Fetching discounts for facility:',
        facilityId,
        'Refresh count:',
        refresh
      );
      const discountResponse = await fetch(
        `${apiUrl}/v1/facilities/${facilityId}/discounts`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!discountResponse.ok) {
        throw new Error('Failed to fetch discounts');
      }
      const discounts = await discountResponse.json();

      // Fetch filters after discounts are loaded.
      const filters = await fetchFilters();

      // For each discount, determine the toggle state for each API column.
      // Logic: If a matching filter record exists, then the discount is filtered off (active=false)
      // and we store its id in filterId.
      const discountsData = discounts.map((item) => {
        const webCmsFilter = filters.find(
          (f) =>
            parseInt(f.filter_value, 10) === item.id &&
            f.api_key_id === discountTypeMapping.webCms &&
            f.filter_type === 'discount'
        );
        const csrScapeFilter = filters.find(
          (f) =>
            parseInt(f.filter_value, 10) === item.id &&
            f.api_key_id === discountTypeMapping.csrScape &&
            f.filter_type === 'discount'
        );
        const msnFilter = filters.find(
          (f) =>
            parseInt(f.filter_value, 10) === item.id &&
            f.api_key_id === discountTypeMapping.msn &&
            f.filter_type === 'discount'
        );
        return {
          key: item.id,
          discountName: item.name,
          webCms: {
            active: webCmsFilter ? false : true,
            filterId: webCmsFilter ? webCmsFilter.id : null,
          },
          csrScape: {
            active: csrScapeFilter ? false : true,
            filterId: csrScapeFilter ? csrScapeFilter.id : null,
          },
          msn: {
            active: msnFilter ? false : true,
            filterId: msnFilter ? msnFilter.id : null,
          },
        };
      });
      setData(discountsData);
    } catch (error) {
      console.error('Error fetching discounts:', error);
      message.error('Failed to load discounts data.');
    } finally {
      setLoading(false);
    }
  };

  // Fetch discounts (and filters) when facilityId, token, or refresh changes.
  useEffect(() => {
    fetchDiscounts();
  }, [facilityId, token, refresh]);

  // Function to POST a filter record (to mark a discount as filtered off).
  const addFilter = async (discountId, discountType) => {
    try {
      const response = await fetch(
        `${apiUrl}/v1/facilities/${facilityId}/api_filters`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            api_key_id: discountType,
            filter_value: discountId,
            filter_type: 'discount',
          }),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to add filter');
      }
      const newFilter = await response.json();
      return newFilter.id;
    } catch (error) {
      console.error('Error adding filter:', error);
      message.error('Error adding filter');
      return null;
    }
  };

  // Function to DELETE a filter record using its API filter ID.
  const removeFilter = async (api_filter_id) => {
    try {
      const url = `${apiUrl}/v1/facilities/${facilityId}/api_filters/${api_filter_id}`;
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Failed to remove filter');
      }
    } catch (error) {
      console.error('Error removing filter:', error);
      message.error('Error removing filter');
    }
  };

  // Handle toggle changes.
  // The "column" parameter indicates which API (webCms, csrScape, msn) is being toggled.
  // We also pass the existing filterId for that toggle (if any).
  const handleToggle = async (discountId, column, checked, existingFilterId) => {
    const discountType = discountTypeMapping[column];
    if (checked) {
      // Toggling ON means the discount is active, so remove its filter if one exists.
      if (existingFilterId) {
        await removeFilter(existingFilterId);
      }
    } else {
      // Toggling OFF means the discount should be filtered, so add a filter record.
      await addFilter(discountId, discountType);
    }
    // Refresh discounts after update.
    fetchDiscounts();
  };

  // Define table columns. Note that for each toggle we now use the nested object's "active" property,
  // and pass along the stored filterId.
  const columns = [
    {
      title: 'Discount Name',
      dataIndex: 'discountName',
      key: 'discountName',
    },
    {
      title: 'Web/CMS',
      dataIndex: 'webCms',
      key: 'webCms',
      render: (value, record) => (
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          checked={record.webCms.active}
          onChange={(checked) =>
            handleToggle(record.key, 'webCms', checked, record.webCms.filterId)
          }
        />
      ),
    },
    {
      title: 'CSRScape',
      dataIndex: 'csrScape',
      key: 'csrScape',
      render: (value, record) => (
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          checked={record.csrScape.active}
          onChange={(checked) =>
            handleToggle(record.key, 'csrScape', checked, record.csrScape.filterId)
          }
        />
      ),
    },
    {
      title: 'MSN',
      dataIndex: 'msn',
      key: 'msn',
      render: (value, record) => (
        <Switch
          checkedChildren="On"
          unCheckedChildren="Off"
          checked={record.msn.active}
          onChange={(checked) =>
            handleToggle(record.key, 'msn', checked, record.msn.filterId)
          }
        />
      ),
    },
  ];

  return <Table columns={columns} dataSource={data} loading={loading} />;
};

export default DiscountTable;
