import React, { useEffect, useState } from "react";
import {
  Divider,
  Progress,
  Button,
  Form,
  Input,
  Radio,
  notification,
  Spin,
  Upload,
  Tooltip
} from "antd";
import { UploadOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import imageCompression from "browser-image-compression";
import "./facilityDisplayStyles.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getInitialSteps } from "./operationSurveyAdditionalSteps"; // Your steps configuration file

const { TextArea } = Input;

const OperationsAuditSurveyComponent = (props) => {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({});
  const [steps, setSteps] = useState([]);
  const [loading, setLoading] = useState(false);

  // From Redux
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const userEmail = useSelector((state) => state.auth.userEmail);
  const mmApiKey = useSelector((state) => state.auth.mmApiKey);

  // Coordinates for Google Maps
  const siteLat = selectedFacility?.latitude;
  const siteLong = selectedFacility?.longitude;

  // Construct the Google Maps URL (for display in a question)
  const googleMapsUrl =
    siteLat && siteLong
      ? `https://www.google.com/maps/search/?api=1&query=${siteLat},${siteLong}`
      : "https://maps.google.com";

  // Google Place details
  const placeId = selectedFacility.google_place_id;
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS;

  // Helper: Convert event to fileList for Form.Item
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  // Compress Image Function using browser-image-compression
  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };
    try {
      const compressedBlob = await imageCompression(file, options);
      return compressedBlob;
    } catch (error) {
      console.error("Image compression error:", error);
      notification.error({
        message: "Compression Error",
        description: "Failed to compress image. Uploading original file.",
      });
      return file;
    }
  };

  // Custom Upload Request: Compress & return a new File
  const customUploadRequest = async ({ file, onSuccess, onError }) => {
    try {
      const compressedBlob = await compressImage(file);
      const finalFile = new File([compressedBlob], file.name, {
        type: compressedBlob.type || file.type,
      });
      onSuccess({ file: finalFile }, file);
    } catch (error) {
      onError(error);
    }
  };

  // Initialize Steps & Calculate Progress
  const calculateProgress = () => {
    const totalSteps = steps.length;
    const progress = Math.floor((current / totalSteps) * 100);
    return Math.min(progress, 100);
  };

  // finishSurvey: Notify parent to exit the survey.
  const finishSurvey = () => {
    if (props.onSurveyComplete) {
      props.onSurveyComplete();
    } else {
      form.resetFields();
      setFormData({});
      setCurrent(0);
      initializeSteps();
      navigate(`/facilities`);
    }
  };

  const initializeSteps = () => {
    let overallQuestionIndex = 0;
    const processedSteps = getInitialSteps(googleMapsUrl).map((step, stepIndex) => {
      const content = (
        <>
          {step.questions.map((question, questionIndex) => {
            overallQuestionIndex++;
            const radioFieldName = `question_${question.questionId}_answer`;
            const textFieldName = `question_${question.questionId}_text`;
            const followUpFieldName = `question_${question.questionId}_followUp`;
            const photosFieldName = `question_${question.questionId}_photos`;

            return (
              <div key={`${stepIndex}_${questionIndex}`}>
                {/* Render question text with a custom tooltip */}
                <Form.Item>
                  <span>
                    <strong>{question.questionText}</strong>
                    <Tooltip title={question.tooltipText}>
                      <QuestionCircleOutlined style={{ marginLeft: 8, color: "#1890ff" }} />
                    </Tooltip>
                  </span>
                </Form.Item>

                {question.type === "text" ? (
                  <Form.Item
                    name={textFieldName}
                    rules={
                      question.commentRequired
                        ? [{ required: true, message: "This field is required" }]
                        : []
                    }
                  >
                    <TextArea rows={3} placeholder="Enter your response..." />
                  </Form.Item>
                ) : (
                  <>
                    <Form.Item
                      name={radioFieldName}
                      rules={[{ required: true, message: "Please select Yes or No" }]}
                    >
                      <Radio.Group>
                        <Radio value="Yes">Yes</Radio>
                        <Radio value="No">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues[radioFieldName] !== currentValues[radioFieldName]
                      }
                    >
                      {({ getFieldValue }) => {
                        const answer = getFieldValue(radioFieldName);
                        if (answer === "Yes") {
                          return (
                            <Form.Item
                              name={textFieldName}
                              label="Additional Comments"
                            >
                              <TextArea rows={3} placeholder="Optional comments..." />
                            </Form.Item>
                          );
                        } else if (answer === "No") {
                          if (question.followUp) {
                            return (
                              <Form.Item
                                name={followUpFieldName}
                                label={question.followUp.questionText}
                                rules={[{ required: true, message: "This field is required" }]}
                              >
                                <TextArea rows={3} placeholder="Please provide details..." />
                              </Form.Item>
                            );
                          } else {
                            return (
                              <Form.Item
                                name={textFieldName}
                                label="Please explain"
                                rules={[
                                  { required: true, message: "This field is required when you select No" }
                                ]}
                              >
                                <TextArea rows={3} placeholder="Required comments..." />
                              </Form.Item>
                            );
                          }
                        }
                        return null;
                      }}
                    </Form.Item>
                  </>
                )}

                {question.maxPhotos > 0 && (
                  <>
                    {/* If this question requires mandatory uploads, display the instruction */}
                    {question.minPhotos && question.photoUploadLabel && (
                        <div style={{ marginBottom: 8, fontStyle: "italic" }}>
                        {question.photoUploadLabel}
                      </div>
                    )}
                    <Form.Item
                      name={photosFieldName}
                      label={`Upload Photos (max ${question.maxPhotos})`}
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      rules={
                        question.minPhotos
                          ? [
                              {
                                validator: (_, value) =>
                                  value && value.length >= question.minPhotos
                                    ? Promise.resolve()
                                    : Promise.reject(new Error(`Please upload at least ${question.minPhotos} photo(s)`)),
                              },
                            ]
                          : []
                      }
                    >
                      <Upload
                        listType="picture"
                        customRequest={customUploadRequest}
                        multiple
                        maxCount={question.maxPhotos}
                      >
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                      </Upload>
                    </Form.Item>
                  </>
                )}

                <Divider />
              </div>
            );
          })}
        </>
      );

      return {
        title: step.title,
        key: `step_${stepIndex + 1}`,
        content: content,
      };
    });
    setSteps([...processedSteps]);
  };

  useEffect(() => {
    initializeSteps();
    // eslint-disable-next-line
  }, []);

  // Function to Upload a Single Photo (without setting Content-Type header)
  const uploadPhoto = async (file, questionKey, index) => {
    try {
      const microMind = process.env.REACT_APP_MICRO_API_URL;
      const microMindKey = process.env.REACT_APP_MICRO_KEY;
      const formData = new FormData();

      // Convert the index to a letter: 0 -> 'a', 1 -> 'b', etc.
      const suffix = String.fromCharCode(97 + index);
      const uniqueQuestionKey = `${questionKey}_${suffix}`;

      // Append the file and fields to the formData
      formData.append("image", file);
      formData.append("fileName", file.name);
      formData.append("questionKey", uniqueQuestionKey);
      formData.append("facilityId", selectedFacility?.id);
      formData.append("surveyType", "operationSurvey");

      const response = await fetch(`${microMind}/api/survey-pics`, {
        method: "POST",
        body: formData,
        headers: {
          "x-api-key": microMindKey,
          Authorization: `Bearer ${mmApiKey}`,
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Photo upload failed.");
      }
      const result = await response.json();

      notification.success({
        message: "Photo Upload Success",
        description: `Photo uploaded successfully: ${uniqueQuestionKey}`,
      });

      return result;
    } catch (error) {
      console.error("Error uploading photo:", error);
      notification.error({
        message: "Photo Upload Error",
        description: error.message,
      });
    }
  };

  // Submit Survey Data (Text Payload, then Photos)
  const submitSurveyData = async (data) => {
    try {
      const microMind = process.env.REACT_APP_MICRO_API_URL;
      const microMindKey = process.env.REACT_APP_MICRO_KEY;
      data.surveyType = "operationSurvey";
      const response = await fetch(`${microMind}/api/submit-survey`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "x-api-key": microMindKey,
          "Content-Type": "application/json",
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin"
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          errorData.error || "Failed to submit survey. Please try again."
        );
      }
      const result = await response.json();
      return result;
    } catch (error) {
      console.error("Error submitting survey data:", error);
      throw new Error(
        error.message || "Failed to submit survey. Please try again."
      );
    }
  };

  const fetchGoogleReviewData = async () => {
    if (!apiKey || !placeId) {
      return { rating: null, userRatingCount: null };
    }
    try {
      const endpoint = `https://places.googleapis.com/v1/places/${placeId}`;
      const response = await fetch(endpoint, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Goog-Api-Key": apiKey,
          "X-Goog-FieldMask": "rating,userRatingCount",
        },
      });
      if (!response.ok) {
        console.error("Places API request failed with status:", response.status);
        return { rating: null, userRatingCount: null };
      }
      const data = await response.json();
      const { rating, userRatingCount } = data;
      return {
        rating: rating ?? null,
        userRatingCount: userRatingCount ?? null,
      };
    } catch (error) {
      console.error("Error fetching Google rating & userRatingCount:", error);
      return { rating: null, userRatingCount: null };
    }
  };

  // onFinish: Submit Survey (Text data first, then Photos)
  const onFinish = async (finalData = formData) => {
    try {
      const values = await form.validateFields();
      const visitDateTime = new Date().toISOString();
      const mmId = selectedFacility?.id || "default-facility-id";
      const nsaId = selectedFacility?.nsa_id;
      const site_lat = selectedFacility?.latitude;
      const site_long = selectedFacility?.longitude;
      
      let mergedData = {
        ...finalData,
        ...values,
        visitDateTime,
        mmId,
        nsaId,
        site_lat,
        site_long,
        userEmail,
      };

      // Extract photo file lists from mergedData
      let photoUploads = {};
      for (const key in mergedData) {
        if (key.endsWith("_photos")) {
          photoUploads[key] = mergedData[key];
          delete mergedData[key];
        }
      }

      setLoading(true);
      const { rating, userRatingCount } = await fetchGoogleReviewData();
      mergedData.googleRating = rating;
      mergedData.googleReviewCount = userRatingCount;

      // Replace undefined/null values with empty strings
      const replaceUndefinedValues = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (obj[key] === undefined || obj[key] === null) {
            if (key !== "user_lat" && key !== "user_long") {
              obj[key] = "";
            }
          } else if (typeof obj[key] === "object" && obj[key] !== null) {
            replaceUndefinedValues(obj[key]);
          }
        });
      };
      replaceUndefinedValues(mergedData);

      await submitSurveyData(mergedData);
      notification.success({
        message: "Success",
        description: "Your survey has been submitted!",
      });

      // Asynchronously upload each photo one by one
      for (const key in photoUploads) {
        const files = photoUploads[key];
        if (files && Array.isArray(files)) {
          for (let i = 0; i < files.length; i++) {
            await uploadPhoto(files[i].originFileObj || files[i], key, i);
          }
        }
      }

      finishSurvey();
      setLoading(false);
    } catch (error) {
      notification.error({
        message: "Error",
        description: error.message,
      });
      setLoading(false);
    }
  };

  // Next and Previous Step Logic
  const next = () => {
    form
      .validateFields()
      .then((values) => {
        const updatedFormData = { ...formData, ...values };
        setFormData(updatedFormData);
        if (current === steps.length - 1) {
          onFinish(updatedFormData);
        } else {
          setCurrent(current + 1);
          form.resetFields();
        }
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
        notification.error({
          message: "Validation Error",
          description: "Please complete the required fields before proceeding.",
        });
      });
  };

  const prev = () => {
    setCurrent(current - 1);
    form.setFieldsValue(formData);
  };

  if (steps.length === 0) {
    return (
      <div style={{ textAlign: "center", marginTop: 50 }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div style={{ padding: "0 16px" }}>
      <Button onClick={finishSurvey} style={{ marginBottom: "16px" }}>
        Back
      </Button>
      <Progress percent={calculateProgress()} />
      <div className="steps-content" style={{ marginTop: 24 }}>
        <Form form={form} layout="vertical">
          {steps[current] && steps[current].content}
        </Form>
      </div>
      <div className="steps-action" style={{ marginTop: 24 }}>
        {current > 0 && (
          <Button style={{ margin: "0 8px" }} onClick={prev}>
            Previous
          </Button>
        )}
        <Button type="primary" onClick={next} loading={loading}>
          Next
        </Button>
      </div>
    </div>
  );
};

export default OperationsAuditSurveyComponent;
