import React, { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  notification,
  Divider,
} from "antd";
import "./facilityStyles.css";
import { useSelector } from "react-redux";
import { updateFacility } from "../features/facilities/facilitiesSlice";
import store from "../redux/store";
import moment from "moment-timezone";
const { Option } = Select;

const EditBasicFacilityDetails = () => {
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedTimeZone, setSelectedTimeZone] = useState(moment.tz.guess());

  useEffect(() => {
    const initialValues = getInitialValues();
    form.setFieldsValue(initialValues);
  }, [selectedFacility]);

  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);

  const handleTimeZoneChange = (value) => setSelectedTimeZone(value);

  const getInitialValues = () => {
    let initialValues = { ...selectedFacility };
    initialValues.emergencyGateCode = initialValues.custom?.emergencyGateCode || '';
    initialValues.partnership = initialValues.custom?.partnership || '';
    initialValues.acquisition_date = initialValues.custom?.acquisition_date || '';
    initialValues.facilities_coordinator = initialValues.custom?.facilities_coordinator || '';
    initialValues.msa = initialValues.custom?.msa || '';
    initialValues.commercial_office_spaces = initialValues.custom?.commercial_office_spaces || '';
    initialValues.billboard = initialValues.custom?.billboard || '';
    initialValues.cell_tower = initialValues.custom?.cell_tower || '';
    initialValues.units = initialValues.custom?.units || '';
    initialValues.property_square_footage = initialValues.custom?.property_square_footage || '';
    initialValues.annexSite = initialValues.custom?.annexSite || '';
    initialValues.site_address = initialValues.site_address || '';
    initialValues.email_address = initialValues.email_address || '';
    initialValues.divisionGeography = initialValues.custom?.divisionGeography || '';
    initialValues.regionalManager = initialValues.custom?.regionalManager || '';
    initialValues.regionalManagerPhoneNumber = initialValues.custom?.regionalManagerPhoneNumber || '';
    initialValues.regionalManagerEmail = initialValues.custom?.regionalManagerEmail || '';
    initialValues.areaManager = initialValues.custom?.areaManager || '';
    initialValues.areaManagerPhoneNumber = initialValues.custom?.areaManagerPhoneNumber || '';
    initialValues.areaManagerEmail = initialValues.custom?.areaManagerEmail || '';
    initialValues.areaNumber = initialValues.custom?.areaNumber || '';
    initialValues.companyCode = initialValues.custom?.companyCode || '';
    initialValues.entityCode = initialValues.custom?.entityCode || '';
    initialValues.rmAnalyst = initialValues.custom?.rmAnalyst || '';
    initialValues.apartmentOnSite = initialValues.custom?.apartmentOnSite || '';
    initialValues.commercial_Lease_Title_Holder = initialValues.custom?.commercial_Lease_Title_Holder || '';
    initialValues.county = initialValues.custom?.county || '';
    initialValues.people_Ops_Partner = initialValues.custom?.people_Ops_Partner || '';
    initialValues.accounting_partner = initialValues.custom?.accounting_partner || '';
    initialValues.nsa_id =  initialValues.nsa_id || '';
    initialValues.name =  initialValues.name || '';
    return initialValues;
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      let transformedValues = transformPayload(values, selectedTimeZone, roles);
      const actionResult = await store.dispatch(
        updateFacility({
          id: selectedFacility.id,
          data: transformedValues,
          userRoles: roles,
          userEmail: email
        })
      );

      if (updateFacility.fulfilled.match(actionResult)) {
        openSuccessNotification("This facility has been successfully updated!");
        onClose();
      } else {
        openErrorNotification(
          "There was an issue updating this facility. Please try again."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      openErrorNotification(
        "There was an issue updating this facility. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const isSpecialEmail = (email) => {
    const specialEmails = [
      'JEsbenshade@nsareit.net',
      'agoris@nsareit.net',
      'mhall@nsabrands.com',
      'CWess@nsabrands.com',
      'kperez@nsareit.net',
      'Johnnyalambo@gmail.com',
      'jswanson@nsareit.net'
    ];
    return specialEmails.includes(email);
  };

  const transformPayload = (values, selectedTimeZone, roles) => {
    const payload = {
      facility: {
        phone_number: values.phone_number,
        email_address: values.email_address,
        website: values.website,
        map: values.map,
        latitude: values.latitude,
        longitude: values.longitude
      },
    };
  
    if (roles.includes('admin') || roles.includes('super_admin') || roles.toLowerCase()?.includes("operator")) {
      payload.facility = {
        phone_number: values.phone_number,
        email_address: values.email_address,
        website: values.website,
        map: values.map,
        original_id: values.original_id,  
        latitude: values.latitude,
        longitude: values.longitude
      }
      payload.facility.custom = {
        units: values.units,
        property_square_footage: values.property_square_footage,
        emergencyGateCode: values.emergencyGateCode,
        partnership: values.partnership,
        acquisition_date: values.acquisition_date,
        facilities_coordinator: values.facilities_coordinator,
        msa: values.msa,
        commercial_office_spaces: values.commercial_office_spaces,
        billboard: values.billboard,
        cell_tower: values.cell_tower,  
        annexSite: values.annexSite,
        divisionGeography: values.divisionGeography,
        regionalManager: values.regionalManager,
        regionalManagerPhoneNumber: values.regionalManagerPhoneNumber,
        regionalManagerEmail: values.regionalManagerEmail,
        areaManager: values.areaManager,
        areaManagerPhoneNumber: values.areaManagerPhoneNumber,
        areaManagerEmail: values.areaManagerEmail,
        areaNumber: values.areaNumber,
        companyCode: values.companyCode,
        entityCode: values.entityCode,
        rmAnalyst: values.rmAnalyst,
        apartmentOnSite: values.apartmentOnSite,
        commercial_Lease_Title_Holder: values.commercial_Lease_Title_Holder,
        people_Ops_Partner: values.people_Ops_Partner,
        accounting_partner: values.accounting_partner,
        county: values.county,

      };
    }
    if (true) {
      payload.facility = {
        phone_number: values.phone_number,
        email_address: values.email_address,
        website: values.website,
        map: values.map,
        original_id: values.original_id,  
        nsa_id: values.nsa_id,  
        name: values.name,  
        latitude: values.latitude,
        longitude: values.longitude

      }
      payload.facility.custom = {
        units: values.units,
        property_square_footage: values.property_square_footage,
        emergencyGateCode: values.emergencyGateCode,
        partnership: values.partnership,
        acquisition_date: values.acquisition_date,
        facilities_coordinator: values.facilities_coordinator,
        msa: values.msa,
        commercial_office_spaces: values.commercial_office_spaces,
        billboard: values.billboard,
        cell_tower: values.cell_tower,  
        annexSite: values.annexSite,
        divisionGeography: values.divisionGeography,
        regionalManager: values.regionalManager,
        regionalManagerPhoneNumber: values.regionalManagerPhoneNumber,
        regionalManagerEmail: values.regionalManagerEmail,
        areaManager: values.areaManager,
        areaManagerPhoneNumber: values.areaManagerPhoneNumber,
        areaManagerEmail: values.areaManagerEmail,
        areaNumber: values.areaNumber,
        companyCode: values.companyCode,
        entityCode: values.entityCode,
        rmAnalyst: values.rmAnalyst,
        apartmentOnSite: values.apartmentOnSite,
        commercial_Lease_Title_Holder: values.commercial_Lease_Title_Holder,
        people_Ops_Partner: values.people_Ops_Partner,
        accounting_partner: values.accounting_partner,
        county: values.county,
      };
    }
    return payload;
  };

 

  const openSuccessNotification = (message) => {
    notification.success({
      message: "Success",
      description: message,
      placement: "topRight",
    });
  };

  const openErrorNotification = (message) => {
    notification.error({
      message: "Error",
      description: message,
      placement: "topRight",
    });
  };

  const hasRequiredRole = () => {
    const lowerRoles = roles.toLowerCase();
    return lowerRoles.includes("admin") || lowerRoles.includes("operator");
  };

  return (
    <>
      {hasRequiredRole() && (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          Edit Basic Details
        </Button>
      )}
      <Drawer
        title="Edit Basic Facility Details"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Submit
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            form={form}
            initialValues={getInitialValues()}
            hideRequiredMark
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="phone_number" label="Phone Number">
                  <Input placeholder="Enter phone number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="email_address" label="Email Address">
                  <Input placeholder="Enter email address" />
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="latitude" label="Latitude">
                  <Input placeholder="Enter latitude" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="longitude" label="Longitude">
                  <Input placeholder="Enter longitude" />
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="map" label="Map">
                  <Input placeholder="Enter map" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="website" label="Website">
                  <Input placeholder="Enter website" />
                </Form.Item>
              </Col>
            </Row>
             <Row gutter={16}>
              {/* <Col span={12}>
                <Form.Item name="timezone" label="Timezone">
                  <Select
                    value={selectedTimeZone}
                    onChange={handleTimeZoneChange}
                    style={{ width: "100%" }}
                  >
                    {moment.tz.names().map((zone) => (
                      <Option key={zone} value={zone}>
                        {zone}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>  */}
              <Col span={12}>
                <Form.Item name="emergencyGateCode" label="Emergency Gate Code">
                  <Input placeholder="Enter emergency gate code" />
                </Form.Item>
              </Col>
            </Row> 
            {(roles.includes('admin') || roles.includes('super_admin') || roles.toLowerCase()?.includes("operator")) && (
              <>
                <Divider>Ops Alignment</Divider>
                      {/* Nested condition for specific email */}
                      {email === 'Johnnyalambo@gmail.com' || email === 'kperez@nsareit.net' && (
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item name="nsa_id" label="NSA #">
                        <Input placeholder="nsa_id" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="name" label="Store Name">
                        <Input placeholder="name" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="partnership" label="Partnership">
                      <Input placeholder="Enter partnership" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="acquisition_date" label="Acquisition Date">
                      <Input placeholder="Enter acquisition date" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="facilities_coordinator" label="Facilities Coordinator">
                      <Input placeholder="Enter facilities coordinator" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="msa" label="MSA">
                      <Input placeholder="Enter MSA" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="commercial_office_spaces" label="Commercial Office Spaces">
                      <Input placeholder="Enter commercial office spaces" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="billboard" label="Billboard on Site">
                      <Input placeholder="Enter billboard on site" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="cell_tower" label="Cell Tower on Site">
                      <Input placeholder="Enter cell tower on site" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="original_id" label="Original Store ID">
                      <Input placeholder="Enter original store ID" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="units" label="Unit Count">
                      <Input placeholder="Enter unit count" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="property_square_footage" label="Property Square Footage">
                      <Input placeholder="Enter property square footage" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="annexSite" label="Annex Site">
                      <Input placeholder="Enter annex site" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="site_address" label="Site Address">
                      <Input placeholder="Enter site address" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="divisionGeography" label="Division Geography">
                      <Input placeholder="Enter division geography" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="regionalManager" label="Regional Manager">
                      <Input placeholder="Enter regional manager" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="regionalManagerPhoneNumber" label="Regional Manager Phone Number">
                      <Input placeholder="Enter regional manager phone number" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="regionalManagerEmail" label="Regional Manager Email">
                      <Input placeholder="Enter regional manager email" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="areaManager" label="Area Manager">
                      <Input placeholder="Enter area manager" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="areaManagerPhoneNumber" label="Area Manager Phone Number">
                      <Input placeholder="Enter area manager phone number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="areaManagerEmail" label="Area Manager Email">
                      <Input placeholder="Enter area manager email" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="areaNumber" label="Area Number">
                      <Input placeholder="Enter area number" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="companyCode" label="Company Code">
                      <Input placeholder="Enter company code" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="entityCode" label="Entity Code">
                      <Input placeholder="Enter entity code" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="rmAnalyst" label="RM Analyst">
                      <Input placeholder="Enter RM analyst" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="apartmentOnSite" label="Apartment on Site">
                      <Input placeholder="Enter apartment on site" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="commercial_Lease_Title_Holder" label="Commercial Lease Title Holder">
                      <Input placeholder="Enter Commercial Lease Title Holder" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name="people_Ops_Partner" label="People Ops Partner">
                      <Input placeholder="Enter People Ops Partner" />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item name="accounting_partner" label="Accounting Partner">
                      <Input placeholder="Enter Accounting Partnert" />
                    </Form.Item>
                    <Form.Item name="county" label="County">
                      <Input placeholder="Enter County" />
                    </Form.Item>
                  </Col>
                </Row>
              </>
            )}
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditBasicFacilityDetails;
