export const getInitialSteps = (googleMapsUrl) => {
  return [
    // Step 1: Operations Visit
    {
      title: "Operations Visit",
      questions: [
        {
          questionId: "op_visit_pm_name",
          questionText:
            "Please type in the name of the PM or APM that was on duty at the time of this audit.",
          tooltipText:
            "Enter the full name of the PM or APM on duty during the audit.",
          context: "",
          type: "text",
          commentRequired: false,
          maxPhotos: 0
        }
      ]
    },
    // Step 2: First Impressions
    {
      title: "First Impressions",
      questions: [
        {
          questionId: "q1",
          questionText: "Property curb appeal presentable?",
          tooltipText:
            "Landscaping not overgrown, well kept, no debris/trash in parking lot, etc.",
          context:
            "Landscaping not overgrown, well kept, no debris/trash in parking lot, etc.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for Property curb appeal
          minPhotos: 1,
          photoUploadLabel: "*Photo of Property Curb Appeal Required"
        },
        {
          questionId: "q2",
          questionText: "Parking Availability?",
          tooltipText:
            "Team members vehicles parked in assigned area / ADA parking.",
          context:
            "Team members vehicles parked in assigned area / ADA parking",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for Parking Availability
          minPhotos: 1,
          photoUploadLabel: "*Photo of Parking Lot Required"
        },
        {
          questionId: "q3",
          questionText: "Office & Restroom Clean?",
          tooltipText:
            "Clean, well stocked and free of personal items.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for Office & Restroom Clean (2 photos required)
          minPhotos: 1,
          photoUploadLabel: "*Photo of Office and Photo of Restroom Required"
        },
        {
          questionId: "q4",
          questionText: "Team Member in Proper Attire?",
          tooltipText:
            "Refer to NSA Look Book and review with PM.",
          context: "Refer to NSA Look Book and review with PM.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 0
        },
        {
          questionId: "q5",
          questionText: "Office Posters & Signage Compliancy",
          tooltipText:
            "OSHA, Insurance, approved office signage, current brand only represented.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for Office Posters & Signage Compliancy
          minPhotos: 1,
          photoUploadLabel: "*Photo(s) of Office Posters Required"
        }
      ]
    },
    // Step 3: Property
    {
      title: "Property",
      questions: [
        {
          questionId: "q6",
          questionText: "Office decor compliance",
          tooltipText:
            "No holiday decor, candles, plugins, homemade signs.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q7",
          questionText:
            "Inspect company units for cleanliness, safety, and account accuracy in SSM",
          tooltipText:
            "Clean organize and setup in SSM per policy/standardization – picture of company unit required.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for Inspecting company units
          minPhotos: 1,
          photoUploadLabel: "*Photo of Company Unit Required"
        },
        {
          questionId: "q8",
          questionText:
            "Drive Aisles, Hallways, Elevator & Dumpster clean, free of trash, debris",
          tooltipText:
            "Verify these areas meet company standards.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for Drive Aisles, Hallways, etc.
          minPhotos: 1,
          photoUploadLabel: "*Photo of Category Listed Required"
        },
        {
          questionId: "q9",
          questionText: "Inspect 5 vacant units with PM for cleanliness",
          tooltipText:
            "Verify units are clean and ready.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 5,
          // Mandatory photo upload for inspecting vacant units (3 photos required)
          minPhotos: 3,
          photoUploadLabel: "*3 Vacant Unit Photos w/Unit # Required"
        }
      ]
    },
    // Step 4: Operations
    {
      title: "Operations",
      questions: [
        {
          questionId: "q10",
          questionText: "Deposit Verification",
          tooltipText:
            "The deposits are being processed / uploaded per company policy. Spot check 5–10 deposits and cash accounts, if applicable.",
          context:
            "Deposits are being processed/uploaded per company policy. Spot check 5–10 deposits and cash counts if applicable.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q11",
          questionText:
            "Verify Google directions to property & Business information is accurate",
          tooltipText:
            "Use Google maps and Google the site to confirm.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          googleMapsUrl: googleMapsUrl,
          // Mandatory photo upload for verifying Google directions
          minPhotos: 1,
          photoUploadLabel: "*Upload of Screenshot of Google Requires"
        },
        {
          questionId: "q12",
          questionText:
            "Conduct a merchandise count and ensure display is fully stocked",
          tooltipText:
            "Count merchandise inventory and compare to inventory in SSM. Verify merch wall meets NSA standards.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3,
          // Mandatory photo upload for merchandise count
          minPhotos: 1,
          photoUploadLabel: "*Photo of Merch Wall Required"
        },
        {
          questionId: "q13",
          questionText: "New Move-In verification",
          tooltipText:
            "Spot check 10 accounts for accuracy and completeness including address, phone number and any additional applicable forms.",
          context:
            "Spot check 10 accounts for accuracy and completeness including address, phone number and any additional applicable forms.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q14",
          questionText: "Move Out Verification",
          tooltipText:
            "Spot check 10 move outs to ensure contents of physical folder have been removed and filed accordingly.",
          context:
            "Spot check 10 move outs to ensure contents of physical folder have been removed and filed accordingly.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q15",
          questionText: "Store phone # active/E911 functional (933)",
          tooltipText:
            "Call to verify # is active and test emergency code.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q16",
          questionText: "Verify PM weekly walk thru in The Vault",
          tooltipText:
            "Verify walkthru is being done weekly and leave comment on observation.",
          context: "",
          type: "text",
          commentTrigger: false,
          commentLabel: "Next Steps/Comments",
          commentRequired: false,
          maxPhotos: 3
        },
        {
          questionId: "q17",
          questionText: "Spot check 5 rental opportunity prospects",
          tooltipText:
            "Perform a spot check on five potential rental opportunity prospects.",
          context: "Verify store is following policy.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q18",
          questionText: "PBI/KPI Performance",
          tooltipText:
            "Conversions > 50%. AR < 8%, Google reviews 6/mo, Merch $15/rental, and Missed calls < 25%. Bad debt < 2.5%, Auto pay > 60%, Office Units < 1.5%, Tenant Insurance < 30%, Revenue, Expenses, NOI.",
          context:
            "Conversions > 50%. AR < 8%, Google reviews 6/mo, Merch $15/rental, and Missed calls < 30%.",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q19",
          questionText: "Check email signature for company standardization",
          tooltipText:
            "Please refer to handbook for proper email signature.",
          context: "",
          type: "boolean",
          commentTrigger: true,
          commentLabel: "Next Steps/Comments",
          commentRequiredOnNo: true,
          maxPhotos: 3
        },
        {
          questionId: "q20",
          questionText: "Review open Jira tickets for additional follow up",
          tooltipText:
            "Review open Jira tickets and either close or follow up with assignee. Leave comments on observations.",
          context: "",
          type: "text",
          commentTrigger: false,
          commentLabel: "Next Steps/Comments",
          commentRequired: false,
          maxPhotos: 3
        }
      ]
    }
  ];
};
