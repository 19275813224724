import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Card } from 'antd';
import { Outlet } from 'react-router-dom';

import StatusTable from './StatusTable';
import DiscountTable from './DiscountTable';
import SettingsForm from './SettingsForm';
import FacilityOverview from './FacilityOverviewPage';
import FacilityDetails from './FacilityDetailsPage';
import Engrain from '../../src/features/engrain/Engrain';
import DataWarehouseDetails from './DataWarehouseDisplay';
import AmSurveyDisplay from './AmSurveyDisplay';
import StoreAudit from './StoreAuditDisplay';

const { TabPane } = Tabs;

const FacilityTabs = () => {
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const roles = useSelector((state) => state.auth.userRoles);
  const facilityId = selectedFacility?.id; // Extract facility ID from the Redux store
  const email = useSelector((state) => state.auth.userEmail);

  // discountRefresh state to trigger re-fetch on Discounts tab click
  const [discountRefresh, setDiscountRefresh] = useState(0);

  // Function to check if engrain properties are present and not empty
  const hasValidEngrainData = () => {
    return (
      selectedFacility &&
      selectedFacility.engrain &&
      selectedFacility.engrain.h != null &&
      selectedFacility.engrain.d != null &&
      selectedFacility.engrain.asset_id != null
    );
  };

  // Handle tab change (e.g., trigger geolocation logic on "Property Survey" tab)
  const handleTabChange = (key) => {
    console.log(email);
    if (key === '2') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const location = {
              lat: position.coords.latitude,
              lon: position.coords.longitude,
            };
            // Store location in localStorage
            localStorage.setItem('userLocation', JSON.stringify(location));
            console.log('Location saved:', location);
          },
          (error) => {
            console.warn(
              'Unable to retrieve location. Location services are disabled or an error occurred.',
              error
            );
          }
        );
      } else {
        console.warn('Geolocation is not supported by this browser.');
      }
    }
  };

  // Handle tab clicks to trigger refresh on the Discounts tab
  const handleTabClick = (key) => {
    if (key === '9') {
      setDiscountRefresh((prev) => prev + 1);
    }
  };

  return (
    <Card style={{ background: '#F5F5F5' }}>
      <Tabs
        defaultActiveKey="1"
        type="card"
        onChange={handleTabChange}
        onTabClick={handleTabClick}
      >
        <TabPane tab="Facility Overview" key="1">
          <FacilityOverview />
        </TabPane>

        <TabPane tab="Property Survey" key="2">
          <StoreAudit facilityId={facilityId} />
        </TabPane>

        <TabPane tab="Custom Fields" key="3">
          <FacilityDetails />
        </TabPane>

        {hasValidEngrainData() && (
          <TabPane tab="Store Map" key="4">
            <Engrain />
          </TabPane>
        )}

        <TabPane tab="Status Page" key="5">
          <StatusTable facilityId={facilityId} />
        </TabPane>

        {!roles.includes('PropertyManager') && !roles.includes('AreaManager') && (
          <TabPane tab="Settings Page" key="6">
            <SettingsForm facilityId={facilityId} />
          </TabPane>
        )}

        {JSON.stringify(roles).includes('admin') && (
          <TabPane tab="Data Warehouse" key="7">
            <DataWarehouseDetails facilityId={facilityId} />
          </TabPane>
        )}
        
        {(email === 'Johnnyalambo@gmail.com' ||
          email === 'kperez@nsareit.net' ||
          email === 'kpatterson@nsabrands.com' ||
          email === 'jswanson@nsareit.net' ||
          roles.includes('AreaManager')) && (
          <TabPane tab="AM Store Audit" key="8">
            <AmSurveyDisplay facilityId={facilityId} />
          </TabPane>
        )}


        <TabPane tab="Discounts" key="9">
          <DiscountTable facilityId={facilityId} refresh={discountRefresh} />
        </TabPane>
      </Tabs>

      {/* Render nested routes (such as /facilities/:id/operations-survey) here */}
      <Outlet />
    </Card>
  );
};

export default FacilityTabs;
