import React, { useState } from "react";
import { Row, Col, Card } from "antd";

// Import your survey components. Replace these with your actual components.
import OperationsAuditSurveyComponent from "../pages/OperationsAuditSurvey";
// import FacilitySurveyComponent from "./FacilitySurveyComponent"; // create this if needed
// import SafetySurveyComponent from "./SafetySurveyComponent"; // create this if needed

const AmSurveyDisplay = ({ facilityId }) => {
  // surveyType will be null (no survey selected) or one of the strings: 'operations', 'facility', 'safety'
  const [surveyType, setSurveyType] = useState(null);

  const handleStartClick = (type) => {
    setSurveyType(type);
  };

  // If a survey has been selected, display the corresponding survey component.
  // The survey component receives an onSurveyComplete callback that resets surveyType to null.
  if (surveyType) {
    return (
      <Card title="AM Store Audit">
        {surveyType === "operations" && (
          <OperationsAuditSurveyComponent 
            facilityId={facilityId} 
            onSurveyComplete={() => setSurveyType(null)}
          />
        )}
        {/* Uncomment and update the following if you add these surveys later:
        {surveyType === "facility" && (
          <FacilitySurveyComponent 
            facilityId={facilityId} 
            onSurveyComplete={() => setSurveyType(null)}
          />
        )}
        {surveyType === "safety" && (
          <SafetySurveyComponent 
            facilityId={facilityId} 
            onSurveyComplete={() => setSurveyType(null)}
          />
        )} */}
      </Card>
    );
  }

  // Otherwise, show the list of surveys as horizontally aligned cards.
  return (
    <Card title="AM Store Audit">
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Card title="Operations Survey" style={{ height: "170px" }}>
            <div>
              <a
                onClick={() => handleStartClick("operations")}
                style={{ cursor: "pointer" }}
              >
                Start
              </a>
            </div>
            <br />
            <div>
              <a 
                href="/files/AM Audit Key.xlsx" 
                target="_blank" 
                rel="noopener noreferrer"
                style={{ color: "red" }}
              >
                Review Operations Question Key
              </a>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card title="Facility Survey" style={{ height: "170px" }}>
            <div style={{ color: "grey", cursor: "not-allowed" }}>
              Coming soon...
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card title="Safety Survey" style={{ height: "170px" }}>
            <div style={{ color: "grey", cursor: "not-allowed" }}>
            Coming soon...
            </div>
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default AmSurveyDisplay;
