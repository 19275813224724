import React, { useState, useEffect } from 'react';
import { Upload, message, Select, Button, Divider, Modal, Spin } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useSelector } from 'react-redux';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import './UploadFacilityImagesComponent.css';

const { Option } = Select;

const microMind = process.env.REACT_APP_MICRO_API_URL;
const microMindKey = process.env.REACT_APP_MICRO_KEY; // Get microMindKey from env

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// New helper method to check if an image URL renders
const checkImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => reject(false);
    img.src = url;
  });
};

const imageCategories = [
  { name: 'Beauty Shot', maxCount: 1 },
  { name: 'Office', maxCount: 1 },
  { name: 'Interior Hallway', maxCount: 1 },
  { name: 'Exterior Driveway', maxCount: 1 },
  { name: 'Security Screens', maxCount: 1 },
  { name: 'Access Control', maxCount: 1 },
  { name: 'Office Front Door', maxCount: 1 },
];

const UploadFacilityImagesComponent = () => {
  const { confirm } = Modal;

  const roles = useSelector((state) => state.auth.userRoles);
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const mmApiKey = useSelector((state) => state.auth.mmApiKey);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);
  const [categoryFiles, setCategoryFiles] = useState({});
  const [optionalImages, setOptionalImages] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const userEmail = 'jswanson@nsareit.net'; // Retrieve the user email

  // Temporary state to hold the selected category before saving
  const [selectedCategory, setSelectedCategory] = useState({});

  function fromSnakeCase(str) {
    return str
      // Replace underscores with spaces
      .replace(/_/g, ' ')
      // Capitalize the first letter of each word
      .replace(/\b\w/g, letter => letter.toUpperCase())
      // Optionally, add more logic for inserting dashes or other characters as needed
      .replace(/\b(With|Various|Characters)\b/g, ' - $1');
  }

  // Helper to validate an array of images using checkImage
  const validateGroup = async (images) => {
    const validatedImages = await Promise.all(
      images.map(img =>
        checkImage(img.url)
          .then(() => img)
          .catch(() => null)
      )
    );
    return validatedImages.filter(img => img !== null);
  };

  const fetchImages = async () => {
    try {
      const result = await axios.get(`${microMind}/api/pictures/${selectedFacility.id}`, {
        headers: {
          'Authorization': `Bearer ${mmApiKey}`
        }
      });
      let filesGroupedByCategory = {};
      let newOptionalImages = []; // Temporary array for optional images

      // Initialize each category with an empty array
      imageCategories.forEach((category) => {
        filesGroupedByCategory[category.name] = [];
      });

      // Iterate over the fetched files and group them by inferred category or optional
      result.data.forEach((file) => {
        const splitName = file.name.split('/');
        const snakeCaseName = splitName[splitName.length - 1]; // Gets the last part after '/'
        const readableCategoryName = fromSnakeCase(snakeCaseName);

        const inferredCategoryName = imageCategories.find(category =>
          fromSnakeCase(category.name) === readableCategoryName
        );

        if (inferredCategoryName && filesGroupedByCategory.hasOwnProperty(inferredCategoryName.name)) {
          filesGroupedByCategory[inferredCategoryName.name].push({
            uid: file.id,
            name: file.name,
            status: 'done',
            url: file.url,
          });
        } else {
          // Add to optional images if not matching any category
          newOptionalImages.push({
            uid: file.id,
            name: file.name,
            status: 'done',
            url: file.url,
          });
        }
      });

      // Validate images: Remove any images whose URL fails to load
      for (const category in filesGroupedByCategory) {
        filesGroupedByCategory[category] = await validateGroup(filesGroupedByCategory[category]);
      }
      newOptionalImages = await validateGroup(newOptionalImages);

      setCategoryFiles(filesGroupedByCategory);
      setOptionalImages(newOptionalImages); // Update state with optional images
    } catch (error) {
      message.error('Failed to load images.');
    }
  };

  useEffect(() => {
    fetchImages();
  }, [selectedFacility.id]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCategoryChange = (categoryId, newFileList) => {
    setCategoryFiles({ ...categoryFiles, [categoryId]: newFileList });
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const handleUpload = async (options, categoryName) => {
    const { file, onSuccess, onError } = options;
    function toSnakeCase(str) {
      return str.toLowerCase().replace(/\s+/g, '_');
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("caption", toSnakeCase(categoryName));
    formData.append("facilityId", selectedFacility.id);

    try {
      const response = await axios.post(`${microMind}/api/pictures/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${mmApiKey}`
        }
      });
      const uploadedFile = {
        uid: response.data.id,
        name: file.name,
        status: 'done',
        url: response.data.url,
      };

      // Update the file list for the category
      setCategoryFiles(prevState => ({
        ...prevState,
        [categoryName]: prevState[categoryName]
          ? [...prevState[categoryName], uploadedFile]
          : [uploadedFile]
      }));

      message.success(`${file.name} file uploaded successfully.`);
      onSuccess(null, file);

      // Enhancement: If the category is "Office Front Door", send a notification.
      if (categoryName === "Office Front Door") {
        const imageNotificationData = {
          category: categoryName,
          fileName: file.name,
          url: response.data.url,
          timestamp: new Date().toISOString(),
        };
        // You can call sendNotification or similar here if needed.
      }
    } catch (error) {
      message.error(`${file.name} file upload failed.`);
      onError(error);
    }
  };

  const handleDelete = async (file) => {
    try {
      const splitName = file.name.split('/');
      const snakeCaseName = splitName[splitName.length - 1]; // Gets the last part after '/'
      // Extract the image ID or any identifier used by your backend from the file object
      await axios.delete(`${microMind}/api/pictures/${selectedFacility.id}/${snakeCaseName}`, {
        headers: {
          'Authorization': `Bearer ${mmApiKey}`
        }
      });
      message.success('Image deleted successfully.');
    } catch (error) {
      message.error('Failed to delete image.');
      console.error('Delete image error:', error);
      // Prevent file from being removed from the list if there's an error
      return false;
    }
  };

  const handleRemove = (file) => {
    return new Promise((resolve, reject) => {
      confirm({
        title: 'Are you sure you want to remove this file?',
        icon: <ExclamationCircleOutlined />,
        content: file.name,
        onOk: async () => {
          const result = await handleDelete(file);
          if (result === false) {
            reject(result);
          } else {
            resolve(result);
          }
        },
        onCancel() {
          reject(false);
        },
      });
    });
  };

  const handleOptionalImagesDelete = async (file) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this image?',
      content: 'This action cannot be undone.',
      onOk: async () => {
        setIsDeleting(true); // Show spinner
        try {
          const splitName = file.name.split('/');
          const snakeCaseName = splitName[splitName.length - 1];
          await axios.delete(`${microMind}/api/pictures/${selectedFacility.id}/${snakeCaseName}`, {
            headers: {
              'Authorization': `Bearer ${mmApiKey}`
            }
          });
          message.success('Image deleted successfully.');
          setIsDeleting(false); // Hide spinner
          setOptionalImages(optionalImages.filter(image => image.uid !== file.uid));
        } catch (error) {
          console.error('Delete image error:', error);
          message.error('Failed to delete image.');
          setIsDeleting(false); // Hide spinner
        }
      },
      onCancel() {
        console.log('Cancel delete');
      },
    });
  };

  const renderUploadAreas = () => {
    return imageCategories.map((category) => (
      <div key={category.name} className="upload-area">
        <ImgCrop rotationSlider aspectSlider showReset quality={1} aspect={16 / 9}>
          <Upload
            listType="picture-card"
            fileList={categoryFiles[category.name] || []}
            onPreview={handlePreview}
            onChange={({ fileList }) => handleCategoryChange(category.name, fileList)}
            customRequest={(options) => handleUpload(options, category.name)}
            onRemove={handleRemove}
            disabled={
              (!roles.toLowerCase().includes('admin') &&
                !roles.toLowerCase().includes('regional') &&
                !roles.toLowerCase().includes('director') &&
                !roles.toLowerCase().includes('super_admin') &&
                !roles.toLowerCase().includes('operator')) ||
              selectedFacility?.custom?.enabledImageUpload === false
            }
          >
            {(!categoryFiles[category.name] ||
              categoryFiles[category.name].length < category.maxCount) && (
                <div>
                  <PlusOutlined />
                  <div className="upload-text">Upload</div>
                </div>
              )}
          </Upload>
        </ImgCrop>
        <div className="category-title">{category.name}</div>
      </div>
    ));
  };

  const renderOptionalImages = () => {
    function toSnakeCase(str) {
      return str
        // Convert the string to lowercase
        .toLowerCase()
        // Replace spaces with underscores
        .replace(/\s+/g, '_');
    }

    // Function to prepare category selection for an image
    const handleCategorySelect = (selectedCategoryName, imageId) => {
      setSelectedCategory(prevState => ({ ...prevState, [imageId]: selectedCategoryName }));
    };

    const saveImageCategory = async (imageId) => {
      const category = selectedCategory[imageId];
      const image = optionalImages.find(img => img.uid === imageId);
      const payload = {
        caption: toSnakeCase(category),
        url: image.url,
        facilityId: selectedFacility.id,
      };
      try {
        const response = await axios.post(`${microMind}/api/pictures/`, payload, {
          headers: {
            'Authorization': `Bearer ${mmApiKey}`
          }
        });
        if (response.status === 201) {
          message.success('Image category updated successfully.');
          fetchImages(); // Refresh images to reflect the change
        } else {
          message.error('Failed to update image category.');
        }
      } catch (error) {
        console.error('Error updating image category:', error);
        message.error('Error during image category update.');
      }
    };

    return optionalImages.map((image) => (
      <div className="upload-area" key={image.uid}>
        <Upload
          listType="picture-card"
          fileList={[{ ...image, status: 'done' }]}
          onPreview={handlePreview}
          onRemove={() => handleOptionalImagesDelete(image)}
          showUploadList={{
            showRemoveIcon: true,
            showPreviewIcon: true,
          }}
          disabled={
            !roles.toLowerCase().includes('admin') &&
            !roles.toLowerCase().includes('operator') &&
            !roles.toLowerCase().includes('regional') &&
            !roles.toLowerCase().includes('director')
              ? true
              : false
          }
        />
        <div className="category-selection">
          <Select
            style={{ width: '35%' }}
            placeholder="Select a category"
            value={selectedCategory[image.uid]}
            onChange={(value) => handleCategorySelect(value, image.uid)}
          >
            {imageCategories.map((category) => (
              <Option key={category.name} value={category.name}>{category.name}</Option>
            ))}
          </Select>
          <Button
            style={{ marginLeft: '10px' }}
            type="primary"
            onClick={() => saveImageCategory(image.uid)}
            disabled={!selectedCategory[image.uid]} // Disable if no category is selected
          >
            Save
          </Button>
        </div>
      </div>
    ));
  };

  return (
    <Spin spinning={isDeleting}>
      <>
        <div className="upload-grid">{renderUploadAreas()}</div>
        {optionalImages.length > 0 && (
          <>
            <Divider>Optional Images</Divider>
            <div className="upload-grid">{renderOptionalImages()}</div>
          </>
        )}
        <Modal
          width={1000}
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </>
    </Spin>
  );
};

export default UploadFacilityImagesComponent;
